//改签搜索
import Q from "q";
import moment from "moment";

// 城市插件
import TrainCitySelector from "yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/train-city-selector/1.1.0/index.vue";

import Calendar from "./../components/calendar";
// 获取时间点，坐席，车型枚举
import trains_common_getTrainEnum from "@/lib/data-service/trains/trains_common_getTrainEnum.js";
// 获取车次余票
import trains_product_selectEndorsedToSearch from "@/lib/data-service/trains/trains_product_selectEndorsedToSearch.js";

// 获取列车时刻表
import trains_common_queryRailwayTimetable from "@/lib/data-service/trains/trains_common_queryRailwayTimetable.js";

export default {
  components: {
    Calendar,
    TrainCitySelector,
  },
  data() {
    return {
      fromStationName: "",
      toStationName: "",
      pickerOptions: {
        disabledDate: this.disabledDate,
      },
      params: {},
      loading: false,
      wrapLoading: false,
      form: {
        fromStationCode: "",
        toStationCode: "",
        trainDate: "",
        trainCode: "",
      },
      formRules: {
        fromStationCode: [
          { required: true, message: "请选择出发城市", trigger: "blur" },
        ],
        toStationCode: [
          { required: true, message: "请选择达到城市", trigger: "blur" },
        ],
        trainDate: [
          { required: true, message: "请选择出发时间", trigger: "blur" },
        ],
        trainCode: [{ required: true, message: "请选择车次", trigger: "blur" }],
      },
      fromCity: {},
      toCity: {},
      dateText: "",
      list: [],
      dataList: [],
      selectForm: {
        trainType: "",
        seatType: "",
      },
      //赛选参数
      selectParams: {
        trainType: [],
        seatType: [],
        departTime: [],
        reachTime: [],
        departStation: [],
        reachStation: [],
        isStarting: [],
      },
      // 可以选参数
      allocation: {
        // 坐席
        seatType: [],
        // 时间段
        trainTimeType: [],
        // 车型
        trainType: [],

        departStation: [],
        reachStation: [],
        isStarting: [
          { text: "始发", key: 2 },
          { text: "路过", key: 3 },
        ],
      }, //
      saveWeek: "2020-02-22",
      initStatu: 1,
      show: false,
      passStation: [],
      tenMinutes: 600,
      tenTimer: null,
      fromCityText: "",
      toCityText: "",
      isInitialize: 0,
    };
  },
  computed: {

  },
  methods: {
    disabledDate(time) {
      return (
        moment(time).diff(moment(), "days") < 0 ||
        moment(time).diff(moment(), "days") > 28
      );
    },
    // 请求车票列表
    req_list(params, type) {
      this.tenMinutes = 600;
      clearInterval(this.tenTimer);
      this.dataList = [];
      if (type === "initialize") this.wrapLoading = true;
      this.loading = true;
      const _this = this;
      Q.when()
        .then(function() {
          return trains_product_selectEndorsedToSearch(params);
        })
        .then(function(res) {
          if (type !== "initialize") {
            _this.fromCityText = _this.fromCity.stationName;
            _this.toCityText = _this.toCity.stationName;
          }

          _this.reset_selectForm();
          _this.list = res.resultList.filter(function(item) {
            const trainSeatVoList = item.trainSeatVoList;
            for (let i = 0; i < trainSeatVoList.length; i++) {
              const seat = trainSeatVoList[i];
              if (seat.seatInventory > 0) return item;
            }
          });
          const allocation = _this.allocation;
          let departStation = [];
          let reachStation = [];
          _this.list.forEach(function(item) {
            const spanTime = item.spanTime;
            if (spanTime) {
              const find = spanTime.indexOf("时");
              item.spanTimeText = find >= 0 ? spanTime : "0时" + spanTime;
            }
            const fromCity = {
              fromStationName: item.fromStationName,
              fromStationCode: item.fromStationCode,
            };

            const toCity = {
              toStationName: item.toStationName,
              toStationCode: item.toStationCode,
            };

            //取得出发站点选项
            departStation = departStation.filter(function(item, index) {
              if (item.fromStationName !== fromCity.fromStationName)
                return item;
            });
            departStation.push(fromCity);
            //取得到达站点选项
            reachStation = reachStation.filter(function(item, index) {
              if (item.toStationName !== toCity.toStationName) return item;
            });
            reachStation.push(toCity);
          });
          allocation.departStation = departStation;
          allocation.reachStation = reachStation;
          _this.dataList = _this.list;
          if (!type) {
            _this.get_days(_this.form.trainDate);
          }
          _this.loading = false;
          if (type === "initialize") _this.wrapLoading = false;
          _this.tenTimer = setInterval(_this.count_down, 1000);
        });
    },
    req_pass_station(params) {
      const _this = this;
      Q.when()
        .then(function() {
          return trains_common_queryRailwayTimetable(params);
        })
        .then(function(res) {
          _this.passStation = res.results;
        });
    },
    station_list(row) {
      const params = {
        fromStationCode: row.fromStationCode,
        toStationCode: row.toStationCode,
        trainCode: row.trainCode,
        trainDate: this.form.trainDate,
      };
      this.req_pass_station(params);
    },
    close_pass_station() {
      this.passStation = [];
    },
    // 搜索条件
    req_search_options() {
      const _this = this;
      Q.when()
        .then(function() {
          return trains_common_getTrainEnum();
        })
        .then(function(res) {
          const allocation = _this.allocation;
          // 车型
          allocation.trainType = res.trainTypeResults;
          // 坐席
          allocation.seatType = res.seatTypeResults;
          // 时间段
          allocation.trainTimeType = res.trainTimeTypeResults;
        });
    },
    get_days(date) {
      const days = moment(date).day();

      switch (days) {
        case 1:
          this.dateText = date + " 星期一";
          break;
        case 2:
          this.dateText = date + " 星期二";
          break;
        case 3:
          this.dateText = date + " 星期三";
          break;
        case 4:
          this.dateText = date + " 星期四";
          break;
        case 5:
          this.dateText = date + " 星期五";
          break;
        case 6:
          this.dateText = date + " 星期六";
          break;
        case 0:
          this.dateText = date + " 星期日";
          break;
      }
    },
    initialize(query) {
      const params = query;
      const form = this.form;
      form.fromStationCode = params.fromStationCode;
      form.toStationCode = params.toStationCode;
      form.trainDate = params.trainDate;
      form.trainCode = params.trainCode;
      this.req_search_options();
      if (form.trainDate) {
        this.get_days(params.trainDate);
        this.change_date(form.trainDate);
      }
      this.fromCity = {
        stationCode: params.fromStationCode,
      };
      this.toCity = {
        stationCode: params.toStationCode,
      };

      this.req_list(this.form, "initialize");
    },
    search() {
      const _this = this;
      this.$refs.form.validate(function(valid) {
        if (valid) {
          _this.req_list(_this.form);
        } else {
          return;
        }
      });
    },
    count_down() {
      this.tenMinutes -= 1;
      if (this.tenMinutes === 0) this.req_list(this.form);
    },
    // 调换出发城市和目的城市
    replace_city() {
      const fromCity = this.fromCity;
      const toCity = this.toCity;
      this.fromCity = toCity;
      this.toCity = fromCity;
    },
    // 时间选择框与多选条件时间组件联动
    change_date(val) {
      if (moment() > moment(val)) val = moment().format("YYYY-MM-DD");
      this.saveWeek = val;
      this.form.trainDate = val;
      const calendar = this.$refs.calendar;
      calendar._getWeek(val);
      calendar.weekList.forEach(function(item) {
        if (item.date === val) {
          item.statu = true;
        } else {
          item.statu = false;
        }
      });
    },
    // 小图标弹出日历
    alter_date() {
      this.$refs.date_alter.pickerVisible = true;
    },
    // 保存时间组件选择值
    clickDate(val) {
      this.saveWeek = val;
      this.form.trainDate = val;
      this.req_list(this.form);
    },
    no_limit(key) {
      this.selectParams[key] = [];
    },
    // 重置多选
    reset_selectForm() {
      this.selectParams = {
        trainType: [],
        seatType: [],
        departTime: [],
        reachTime: [],
        departStation: [],
        reachStation: [],
        isStarting: [],
      };
    },
    // 更多筛选条件伸展按钮
    change_fold() {
      this.show = !this.show;
    },
    select_list_data(params) {
      this.loading = true;
      let list = [...this.list];
      const trainType = params.trainType;
      const seatType = params.seatType;
      const departTime = params.departTime;
      const reachTime = params.reachTime;
      const departStation = params.departStation;
      const reachStation = params.reachStation;

      //车型赛选
      if (trainType.length) {
        list = list.filter(function(trainNo) {
          for (let i = 0; i < trainType.length; i++) {
            const train = trainType[i];
            if (train === trainNo.motorcycleTypeText) return trainNo;
          }
        });
      }

      //坐席赛选
      if (seatType.length) {
        list = list.filter(function(trainNo) {
          for (let i = 0; i < trainNo.trainSeatVoList.length; i++) {
            const trainNoSeat = trainNo.trainSeatVoList[i];
            for (let j = 0; j < seatType.length; j++) {
              const seat = seatType[j];
              if (trainNoSeat.seatName === seat) return trainNo;
            }
          }
        });
      }

      //出发时间赛选
      if (departTime.length) {
        list = list.filter(function(trainNo) {
          for (let i = 0; i < departTime.length; i++) {
            const time = departTime[i];
            const timeStart = time.trainTimeStartText.replace(":", "");
            const timeEnd = time.trainTimeEndText.replace(":", "");
            const fromTime = trainNo.fromTime.replace(":", "");
            if (fromTime >= timeStart && fromTime <= timeEnd) return trainNo;
          }
        });
      }

      //到达时间赛选
      if (reachTime.length) {
        list = list.filter(function(trainNo) {
          for (let i = 0; i < reachTime.length; i++) {
            const time = reachTime[i];
            const timeStart = time.trainTimeStartText.replace(":", "");
            const timeEnd = time.trainTimeEndText.replace(":", "");
            const toTime = trainNo.toTime.replace(":", "");
            if (toTime >= timeStart && toTime <= timeEnd) return trainNo;
          }
        });
      }

      //出发站赛选
      if (departStation.length) {
        list = list.filter(function(trainNo) {
          for (let i = 0; i < departStation.length; i++) {
            const station = departStation[i];
            if (trainNo.fromStationCode === station) return trainNo;
          }
        });
      }

      //到达站赛选
      if (reachStation.length) {
        list = list.filter(function(trainNo) {
          for (let i = 0; i < reachStation.length; i++) {
            const station = reachStation[i];
            if (trainNo.toStationCode === station) return trainNo;
          }
        });
      }
      this.dataList = list;
      this.loading = false;
    },
    // 改签
    ticket_change(row, seat) {
      const fromStationName = row.fromStationName;
      const toStationName = row.toStationName;
      row.seat = seat;
      row = JSON.stringify(row);
      const params = this.$route.query;
      const query = {
        orderNo: params.orderNo,
        newTicketPrice: seat.seatPrice,
        sns: params.sn,
        seat: row,
      };

      let message = "是否继续改签";

      if (
        fromStationName === this.fromStationName &&
        toStationName === this.toStationName
      ) {
        this.$router.push({
          name: "distributor-train-front-ticket-change-affirm",
          query,
        });
      } else {
        if (
          fromStationName !== this.fromStationName &&
          toStationName !== this.toStationName
        ) {
          message = `您的出发站由${this.fromStationName}站变更为${fromStationName}站，到达站由${this.toStationName}站变更为${toStationName}站，是否继续改签？`;
        } else if (fromStationName !== this.fromStationName) {
          message = `您的出发站由${this.fromStationName}站变更为${fromStationName}站，是否继续改签？`;
        } else if (toStationName !== this.toStationName) {
          message = `您的到达站由${this.toStationName}站变更为${toStationName}站，是否继续改签？`;
        }
        this.$confirm(message, "温馨提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              name: "distributor-train-front-ticket-change-affirm",
              query,
            });
          })
          .catch(() => {});
      }
    },
  },
  watch: {
    fromCity(val) {
      this.form.fromStationCode = val ? val.stationCode : "";
      if (!this.fromStationName && val.stationName) {
        this.fromCityText = val.stationName;
        this.fromStationName = val.stationName;
      }
    },
    toCity(val) {
      this.form.toStationCode = val ? val.stationCode : "";
      if (!this.toStationName && val.stationName) {
        this.toCityText = val.stationName;
        this.toStationName = val.stationName;
      }
    },
    selectParams: {
      handler(val) {
        this.select_list_data(val);
      },
      deep: true,
    },
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    this.initialize(this.$route.query);
  },
  created() {},
  activated() {},
  deactivated() {},
};
