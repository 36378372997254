// trains_common_queryRailwayTimetable 获取列车时刻表

const __request = require(`@/lib/data-service/trains/__request/__request_contentType_json`);

// 接口文档地址： http://219.128.52.2:8061/mock/8/trains/common/queryRailwayTimetable
export default function trains_common_queryRailwayTimetable(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  const fromStationCode = pParameter.fromStationCode || ''
  const toStationCode = pParameter.toStationCode || ''
  const trainCode = pParameter.trainCode || ''
  const trainDate = pParameter.trainDate || ''
  params.method = 'POST'
  params.urlSuffix = '/trains/common/queryRailwayTimetable'
  params.data = {
    fromStationCode,
    toStationCode,
    trainCode,
    trainDate
  }
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
